import React, { useState, useRef, useEffect, useCallback } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import "@cloudscape-design/global-styles/index.css"
import { API } from "aws-amplify";
import Button from "@cloudscape-design/components/button"
import Header from "@cloudscape-design/components/header"
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import {
  SpaceBetween,
  AppLayout,
} from "@cloudscape-design/components";
import { DeviceTable } from "./deviceTable";
import { SnapshotTable } from "./snapshotTable";
import { RecentDataChart } from "./recentDataChart";
import { apiName } from "./API";

const App = () => {
  const [snapshot, setSnapshot] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    getSnapshot();
    getChartData();
    document.title = 'Dashboard administration';
  }, []);

  const { chartItems, chactions, chfilteredItemsCount, chartCollectionProps, chfilterProps, chpaginationProps } = useCollection(
    chartData,
    {
      filtering: {},
      pagination: {},
      sorting: {},
      selection: {},
    }
  );

  function getSnapshot() {
    API.get(apiName, '/device/1')
      .then(response => {
        setSnapshot(response.Items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const deviceIdForPlot = '4';

  function getChartData() {
    API.get(apiName, '/history/1')
      .then(response => {
        const chartDataFiltered = response
          .filter(m => m.deviceId === deviceIdForPlot);

        setChartData([
          {
            title: 'U1 average',
            type: 'line',
            color: '#ff0000',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U1_avg)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U1 min',
            type: 'line',
            color: '#ff0000',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U1_min)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U1 max',
            type: 'line',
            color: '#ff0000',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U1_max)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U2 average',
            type: 'line',
            color: '#00ff00',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U2_avg)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U2 min',
            type: 'line',
            color: '#00ff00',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U2_min)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U2 max',
            type: 'line',
            color: '#00ff00',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U2_max)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U3 average',
            type: 'line',
            color: '#0000ff',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U3_avg)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U3 min',
            type: 'line',
            color: '#0000ff',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U3_min)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
          {
            title: 'U3 max',
            type: 'line',
            color: '#0000ff',
            data: chartDataFiltered
              .map(m => ({
                x: new Date(m.timestamp),
                y: parseFloat(m.U3_max)
              }))
              .sort((a, b) => a.x < b.x ? -1 : (a.x > b.x ? 1 : 0))
          },
        ])
      })
      .catch(error => {
        console.log(error)
      });
  }

  const appLayout = useRef();

  const navHeader = { text: 'Service', href: '#/' };

  const navItems = [
    { type: 'link', text: 'Device setup', href: '#/deviceSetup' },
    { type: 'link', text: 'Database setup', href: '#/databaseSetup' },
    { type: "divider" },
    { type: 'link', text: 'Snapshot', href: '#/snapshot' },
    { type: 'link', text: 'Chart', href: '#/chart' },
  ];

  const defaultOnFollowHandler = event => {
    // keep the locked href for our demo pages
    event.preventDefault();
  };

  const [activeHref, setActiveHref] = React.useState("#/deviceSetup");

  const ComponentCond = () => {
    switch (activeHref) {
      case ("#/deviceSetup"): {
        return (<DeviceTable />)
      }
        break;

      case ("#/snapshot"): {
        return (<><SnapshotTable
          snapshot={snapshot}
        />
          <Button onClick={() => { getSnapshot(); }}>Refresh snapshot</Button>
        </>
        )
      }
        break;

      case ("#/chart"): {
        return (<><RecentDataChart
          chartData={chartData}
        />
          <Button onClick={() => { getChartData(); }}>Refresh chart</Button>
        </>
        )
      }
        break;
    }
  }

  return (
    <div className="App">
      <head>
        <title>Dashboard administration</title>
      </head>
      <AppLayout
        ref={appLayout}
        //navigaion={<SideNavigation items={navItems} header={navHeader} activeHref="#/instances" onFollow={defaultOnFollowHandler} />}
        navigation={<SideNavigation
          activeHref={activeHref}
          header={{ text: "Selection" }}
          onFollow={event => {
            if (!event.detail.external) {
              event.preventDefault();
              setActiveHref(event.detail.href);
            }
          }}
          items={navItems}
        />
        }
        content={
          <SpaceBetween direction="vertical" size="s">
            <Header
              variant="h1"
            >
              Dashboard administration
            </Header>

            {
              (() => {
                switch (activeHref) {
                  case ("#/deviceSetup"): {
                    return (<DeviceTable />)
                  }
                    break;

                  case ("#/snapshot"): {
                    return (<><SnapshotTable
                      snapshot={snapshot}
                    />
                      <Button onClick={() => { getSnapshot(); }}>Refresh snapshot</Button>
                    </>
                    )
                  }
                    break;

                  case ("#/chart"): {
                    return (<><RecentDataChart
                      chartData={chartData}
                    />
                      <Button onClick={() => { getChartData(); }}>Refresh chart</Button>
                    </>
                    )
                  }
                    break;
                }
              })()
            }

          </SpaceBetween>
        }
      />
    </div>
  )
}

export default App;
