import React, { useState, useEffect } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { API } from "aws-amplify";
import Header from "@cloudscape-design/components/header"
import {
  Input,
  Table
} from "@cloudscape-design/components";
import { apiName } from "./API";

const deviceColumns = [
  {
    id: "devid",
    minWidth: 80,
    header: "Device id",
    cell: e => e.devid,
    sortingField: "devid"
  },
  {
    id: "serial",
    minWidth: 180,
    header: "Serial number",
    cell: e => e.serial,
    sortingField: "serial",
    editConfig: {
      ariaLabel: 'Edit serial number',
      editIconAriaLabel: 'editable',
      editingCell: (item, { setValue, currentValue }) => {
        return (
          <Input
            autoFocus={true}
            ariaLabel="Edit serial number"
            value={currentValue ?? item.serial}
            onChange={event => {
              setValue(event.detail.value);
            }}
            placeholder="Enter serial number"
          />
        );
      },
    },
  },
  {
    id: "type",
    minWidth: 120,
    header: "Type",
    cell: e => e.type,
    sortingField: "type",
    editConfig: {
      ariaLabel: 'Edit type',
      editIconAriaLabel: 'editable',
      editingCell: (item, { setValue, currentValue }) => {
        return (
          <Input
            autoFocus={true}
            ariaLabel="Edit type"
            value={currentValue ?? item.type}
            onChange={event => {
              setValue(event.detail.value);
            }}
            placeholder="Enter type"
          />
        );
      },
    },
  },
  {
    id: "host",
    minWidth: 180,
    header: "Host",
    cell: e => e.host,
    sortingField: "host",
    editConfig: {
      ariaLabel: 'Edit host',
      editIconAriaLabel: 'editable',
      editingCell: (item, { setValue, currentValue }) => {
        return (
          <Input
            autoFocus={true}
            ariaLabel="Edit host"
            value={currentValue ?? item.host}
            onChange={event => {
              setValue(event.detail.value);
            }}
            placeholder="Enter host"
          />
        );
      },
    },
  },
  {
    id: "location",
    minWidth: 180,
    header: "Location",
    cell: e => e.location,
    sortingField: "location",
    editConfig: {
      ariaLabel: 'Edit location',
      editIconAriaLabel: 'editable',
      editingCell: (item, { setValue, currentValue }) => {
        return (
          <Input
            autoFocus={true}
            ariaLabel="Edit host"
            value={currentValue ?? item.location}
            onChange={event => {
              setValue(event.detail.value);
            }}
            placeholder="Enter location"
          />
        );
      },
    },
  },
];

export function DeviceTable() {
  const [devices, setDevices] = useState([]);
  
  useEffect(() => {
    getDevices();
  }, []);

  function getDevices() {
    API.get(apiName, "/devices")
      .then(response => {
        const deviceList = []
  
        response.Items.forEach((device, index) => {
          deviceList.push({
            devid: device.devid.S,
            serial: device.serial.S,
            type: device.type.S,
            host: device.host.S,
            location: device.location.S,
          })
        })
  
        setDevices(deviceList);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmit = async (currentItem, column, value) => {
    console.log(currentItem);
    console.log(column);
    console.log(value);

    let fullCollection = devices;

    const newItem = { ...currentItem, [column.id]: value };

    setDevices(fullCollection.map(item => (item === currentItem ? newItem : item)));

    const newDevice = {
      devid: { S: newItem.devid },
      serial: { S: newItem.serial },
      type: { S: newItem.type },
      host: { S: newItem.host },
      location: { S: newItem.location },
    };

    const putTest = { body: newDevice };

    API.put(apiName, "/devices", putTest);
  };

   const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    devices,
    {
      filtering: {},
      pagination: {},
      sorting: { defaultState: { sortingColumn: deviceColumns[0] } },
      selection: {},
    }
  ); 

  return (
    <Table
      {...collectionProps}
      submitEdit={handleSubmit}
      columnDefinitions={deviceColumns}
      items={items}
      header={
        <Header variant="h2">Devices</Header>
      }
    />
  )
}