import React from "react";
import Header from "@cloudscape-design/components/header"
import {
  Table
} from "@cloudscape-design/components";

export const SnapshotTable = ({ snapshot }) => {
  return (
    <Table
      columnDefinitions={[
        {
          id: "serial",
          header: "Serial number",
          cell: e => e.devid.S
        },
        {
          id: "u1",
          header: "U1",
          cell: e => e.U1.N + " V"
        },
        {
          id: "u2",
          header: "U2",
          cell: e => e.U2.N + " V"
        },
        {
          id: "u3",
          header: "U3",
          cell: e => e.U3.N + " V"
        },
        {
          id: "i1",
          header: "I1",
          cell: e => e.I1.N + " A"
        },
        {
          id: "i2",
          header: "I2",
          cell: e => e.I2.N + " A"
        },
        {
          id: "i3",
          header: "I3",
          cell: e => e.I3.N + " A"
        },
        {
          id: "p",
          header: "P",
          cell: e => e.P.N + " W"
        },
        {
          id: "q",
          header: "Q",
          cell: e => e.Q.N + " VA"
        },
        {
          id: "f",
          header: "Frequency",
          cell: e => e.F.N + " Hz"
        },
        {
          id: "timestamp",
          header: "Last timestamp",
          cell: e => new Date(e.timestamp.S).toLocaleDateString("sv-SV", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })
        },
      ]}
      empty={<Header variant="h3">Empty!</Header>}
      items={snapshot}
      header={
        <Header variant="h2">Snapshots</Header>
      }
      loadingText="Loading resources"
    />
  )
}