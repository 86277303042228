import React, { useState, useRef, useEffect } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import "@cloudscape-design/global-styles/index.css"
import { API } from "aws-amplify";
import Button from "@cloudscape-design/components/button"
import Header from "@cloudscape-design/components/header"
import {
  SpaceBetween,
  Table,
  Input,
  AppLayout,
  LineChart,
  Box
} from "@cloudscape-design/components";

export const RecentDataChart = ({ chartData }) => {
  return (
    <LineChart
      series={chartData}
      empty={< Box textAlign="center" color="inherit" >
        <b>No data available</b>
        <Box variant="p" color="inherit">
          There is no data available
        </Box>
      </Box >}
      yDomain={[220, 240]}
      i18nStrings={{
        xTickFormatter: e =>
          e.toLocaleDateString("sv-SV", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: !1
          })
            .split(",")
            .join("\n"),
      }}
      xScaleType="time"
    />
  )
}